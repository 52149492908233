import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import { Normalize } from 'styled-normalize'

const rootElement = document.getElementById('root') as HTMLElement

const AppElement = () => (
  <React.StrictMode>
    <Normalize />
    <App />
  </React.StrictMode>
)

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<AppElement />, rootElement)
} else {
  ReactDOM.render(<AppElement />, rootElement)
}
