import * as React from 'react'
import { useRef } from 'react'
import { Background as Theme } from './components/Base'
import { Header } from './components/Header'
import { Body } from './components/Body'
import { Footer } from './components/Footer'
import { FontInjector } from './components/FontInjector'

export function App() {
  const homeRef = useRef<null | HTMLDivElement>(null)
  const projectsRef = useRef<null | HTMLDivElement>(null)
  const contactRef = useRef<null | HTMLDivElement>(null)

  const onHomeClick = () => {
    homeRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const onProjectsClick = () => {
    projectsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const onContactClick = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Theme>
      <FontInjector />
      <Header
        onHomeClick={onHomeClick}
        onProjectsClick={onProjectsClick}
        onContactClick={onContactClick}
      />
      <Body
        homeRef={homeRef}
        projectsRef={projectsRef}
        contactRef={contactRef}
      />
      <Footer />
    </Theme>
  )
}
