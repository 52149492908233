import React from 'react'
import { styled } from 'styled-components'
import ContactEnvelopesSrc from '../static/ContactEnvelopes.png'
import { BtnText, ContactEnvelopesIcon, ProjectTitle } from './Base'

const ContactWrapper = styled.div`
  text-align: center;
  height: 513px;

  @media only screen and (max-width: 1024px) {
    height: 400px;
  }
`

const ContactUs = styled(ProjectTitle)`
  font-size: 47px;
  top: 147px;
  position: relative;
`
const EmailBtn = styled(BtnText)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 185px;
  font-size: 31px;
  cursor: pointer;
  background: #d8d8d8;
  width: 153px;
  height: 65px;
  border-radius: 11px;
  position: relative;
  z-index: 1;
  text-decoration: none;
`

interface ContactProps {
  readonly contactRef: React.MutableRefObject<null | HTMLDivElement>
}

export function Contact(props: ContactProps) {
  const mailto = 'mailto:contact@weblylabs.com'

  return (
    <ContactWrapper ref={props.contactRef}>
      <ContactEnvelopesIcon src={ContactEnvelopesSrc} />
      <ContactUs>contact us</ContactUs>
      <EmailBtn onClick={() => (window.location.href = mailto)}>email</EmailBtn>
    </ContactWrapper>
  )
}
