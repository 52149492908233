import styled from 'styled-components'
import { ColorPalette } from '../ColorPalette'
import { DescriptionProjectText, ProjectLabelText, ProjectText } from './Base'

const Wrapper = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
  padding: 55px;
  background: #ffffff;
  border-radius: 15px;
`
const IconLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  align-items: center;
`

const TitleWrapper = styled.div`
  color: ${ColorPalette.Grey.primary};
  text-align: center;
`

const DescriptionWrapper = styled.div`
  color: #616161;
  width: 85%;
  text-align: center;
  margin: 0 auto;
`

const LabelWrapper = styled.div<Pick<ContentBoxProps, 'color'>>`
  background: ${(props) => props.color};
  text-align: center;
  border-radius: 3px;
  padding: 0px 2px 0px;
  cursor: pointer;
`

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  /* margin-bottom: -20px; */
`

interface ContentBoxProps {
  readonly icon: JSX.Element | string
  readonly title: string
  readonly description: string
  readonly label: string
  readonly color: string
  onClick?(): void
}

export const ContentBox = (props: ContentBoxProps) => {
  return (
    <Wrapper>
      <IconLabelWrapper>
        <IconWrapper>{props.icon}</IconWrapper>
        <TitleWrapper>
          <ProjectText>{props.title}</ProjectText>
        </TitleWrapper>
        <LabelWrapper onClick={props.onClick} color={props.color}>
          <ProjectLabelText>{props.label}</ProjectLabelText>
        </LabelWrapper>
      </IconLabelWrapper>

      <DescriptionWrapper>
        <DescriptionProjectText>{props.description}</DescriptionProjectText>
      </DescriptionWrapper>
    </Wrapper>
  )
}
