import { HideItIcon, MyMoodIcon, StyleToolIcon } from '../components/Base'
import HideItLogoSrc from '../static/HideIt_logo.svg'
import MyMoodLogoSrc from '../static/MyMood_logo.svg'
import StyleToolSrc from '../static/StyleTool_logo.png'

export const TextConfig = {
  body: {
    title: 'projects',
    contentList: [
      {
        id: 1,
        title: 'HideIt',
        description: `Extension for Google Chrome which helps to clear all unwanted popups (invasive and overlay ads, banners) just in one click.`,
        icon: <HideItIcon src={HideItLogoSrc} />,
        color: '#FF924B',
        label: 'TRY IT!',
        tryIt: true
      },
      {
        id: 2,
        title: 'MyMood',
        description: `Wallpaper Chrome extension with different mood attitudes for each day.`,
        icon: <MyMoodIcon src={MyMoodLogoSrc} />,
        color: '#92DBFF',
        label: 'COMMING SOON',
        tryIt: false
      },
      {
        id: 3,
        title: 'StyleTool',
        description: `Extension for creating changes to a website's design and making changes on your own. You will be able to try different styling just in a few simple clicks without programming.`,
        icon: <StyleToolIcon src={StyleToolSrc} />,
        color: '#595959',
        label: 'COMMING SOON',
        tryIt: false
      }
    ]
  }
}
