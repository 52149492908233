import React from 'react'
import { styled } from 'styled-components'
import WeblyIconSrc from '../static/Webly_logo_grey.png'
import { Email, FooterInfoText, ProjectTitle, WeblyIconGrey } from './Base'

const FooterWrapper = styled.div`
  height: 225px;
`
const ContactTitle = styled(ProjectTitle)`
  font-size: 37px;
  margin-left: 43px;
  margin-top: 19px;
  margin-bottom: 33px;
`
const ContactEmail = styled(Email)`
  font-size: 23px;
  margin-left: 43px;
  margin-top: 19px;
`
const SiteInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 47px;
  height: 29px;
`
const WeblyIcon = styled(WeblyIconGrey)``
const InfoText = styled(FooterInfoText)``
const Linkedin = styled(FooterInfoText)`
  cursor: pointer;
`

export function Footer() {
  return (
    <FooterWrapper>
      <ContactTitle>contact</ContactTitle>
      <ContactEmail>contact@weblylabs.com</ContactEmail>
      <SiteInfo>
        <WeblyIcon src={WeblyIconSrc} />
        <InfoText>© 2023 Webly labs</InfoText>
        <Linkedin>Linkedin</Linkedin>
      </SiteInfo>
    </FooterWrapper>
  )
}
