import styled from 'styled-components'
import { TextConfig } from '../config/TextConfig'
import { ContentBox } from './ContentBox'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
`

interface ProjectSectionProps {
  onClick?(): void
}

export const ProjectSection = (props: ProjectSectionProps) => {
  return (
    <Wrapper>
      {TextConfig.body.contentList.map((project) => (
        <ContentWrapper key={project.id}>
          <ContentBox
            title={project.title}
            description={project.description}
            label={project.label}
            color={project.color}
            icon={project.icon}
            onClick={project.tryIt ? props.onClick : undefined}
          />
        </ContentWrapper>
      ))}
    </Wrapper>
  )
}
