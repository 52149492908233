import { styled } from 'styled-components'

export const Background = styled.div`
  background: #efefef;
  font-family: 'Poppins', sans-serif;
`

export const Menu = styled.div`
  display: flex;
  margin-right: 15%;
  color: #4c4c4c;

  @media only screen and (max-width: 800px) {
    margin-right: 0;
  }

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`
export const MenuEl = styled.div`
  padding: 15px 45px;
  font-size: 25px;
  color: #4c4c4c;
  margin-top: 7px;
  font-family: 'Poppins';
  letter-spacing: 2px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 500px) {
    display: block;
    text-align: center;
  }
`

export const WeblyIcon = styled.img`
  padding: 15px;
  height: 51px;
  margin-left: 30px;
`
export const WeblyIconGrey = styled.img`
  height: 41px;
`

export const ProjectTitle = styled.div`
  color: #9c9c9c;
  font-family: 'Poppins';
  letter-spacing: 2px;
  font-weight: 400;
`
export const BtnText = styled.div`
  color: #4c4c4c;
  font-family: 'Poppins';
  letter-spacing: 2px;
  font-weight: 400;
`
export const ProjectText = styled.div`
  font-size: 37px;
  color: #4c4c4c;
  font-family: 'Poppins';
  letter-spacing: 2px;
  font-weight: 400;
  padding: 15px;
`

export const Email = styled.div`
  color: #4c4c4c;
  font-family: 'Poppins';
  letter-spacing: 2px;
  font-weight: 400;
`

export const FooterInfoText = styled.div`
  font-size: 19px;
  color: #4c4c4c;
  font-family: 'Poppins';
  letter-spacing: 0px;
  font-weight: 400;
`
export const ProjectLabelText = styled.div`
  font-size: 13px;
  color: #ffffff;
  font-family: 'Poppins';
  letter-spacing: 2px;
  font-weight: 400;
  border-radius: 3px;
  text-align: center;
  padding: 3px;
`
export const HideItIcon = styled.img`
  height: 192px;
  width: 192px;
`
export const MyMoodIcon = styled.img`
  height: 146px;
  width: 158px;
`
export const StyleToolIcon = styled.img`
  height: 128px;
  width: 128px;
`
export const BackgroundProject = styled.div`
  width: 100%;
  height: 450px;
`
export const ProjectWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

export const DescriptionProjectText = styled.div`
  color: #4c4c4c;
  margin: 0 auto;
  width: 73%;
  font-size: 37px;
  font-family: 'Poppins';
  letter-spacing: 2px;
  font-weight: 400;
`
export const ProjectIcon = styled.div`
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 25px;
  }
`

export const ContactEnvelopesIcon = styled.img`
  height: 431px;
  width: 1207px;
  padding: 41px;
  transform: translateX(-50%);
  position: absolute;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`
