import { createGlobalStyle } from 'styled-components'
import poppinsBoldWoff from '../fonts/poppins-bold.woff'
import poppinsBoldWoff2 from '../fonts/poppins-bold.woff2'
import poppinsSemiBoldWoff from '../fonts/poppins-semiBold.woff'
import poppinsSemiBoldWoff2 from '../fonts/poppins-semiBold.woff2'
import poppinsRegularWoff from '../fonts/poppins-regular.woff'
import poppinsRegularWoff2 from '../fonts/poppins-regular.woff2'

export const FontInjector = createGlobalStyle`
  @font-face {
      font-family: 'Poppins';
      src: url(${poppinsBoldWoff}) format('woff2'), url(${poppinsBoldWoff2}) format('woff');
      font-weight: 600;
      font-style: normal;
  }

  @font-face {
      font-family: 'Poppins';
      src: url(${poppinsSemiBoldWoff2}) format('woff2'), url(${poppinsSemiBoldWoff}) format('woff');
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${poppinsRegularWoff2}) format('woff2'), url(${poppinsRegularWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

`
