import React from 'react'
import { styled } from 'styled-components'
import { ProjectTitle } from './Base'
import { Contact } from './Contact'
import { ProjectSection } from './ProjectsSection'

const HelloText = styled.div`
  width: 47%;
  width: 72%;
  text-align: left;
  margin-top: 80px;
  margin-left: 60px;
  height: 400px;
  font-size: 59px;
  color: #4c4c4c;
  font-family: 'Poppins';
  letter-spacing: 2px;
  font-weight: 400;

  @media only screen and (max-width: 1100px) {
    margin-right: 60px;
    width: 100%;
    position: relative;
    z-index: 1;
  }
`

const Hello = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 249px);
  align-items: center;

  @media only screen and (max-width: 1100px) {
    height: auto;
  }
`

const BodyTitle = styled(ProjectTitle)`
  font-size: 47px;
  text-align: left;
  margin-left: 55px;
`

interface BodyProps {
  readonly homeRef: React.MutableRefObject<null | HTMLDivElement>
  readonly projectsRef: React.MutableRefObject<null | HTMLDivElement>
  readonly contactRef: React.MutableRefObject<null | HTMLDivElement>
}

export function Body(props: BodyProps) {
  const onTryHideItClick = () => {
    console.log('Try Hide It Clicked')
    window.open(
      'https://chrome.google.com/webstore/detail/hide-it/bbmodohebancbmojchpineedcilepcfb?hl=en&authuser=0',
      '_blank',
      'noreferrer'
    )
  }

  return (
    <div>
      <Hello ref={props.homeRef}>
        <HelloText>
          Hello, we are Webly Labs. Developers group with creative minds,
          contemporary vision, and engineering approach. You are very welcome
          here.{' '}
        </HelloText>
      </Hello>
      <BodyTitle ref={props.projectsRef}>projects</BodyTitle>
      <ProjectSection onClick={onTryHideItClick} />
      <Contact contactRef={props.contactRef} />
    </div>
  )
}
