import React from 'react'
import { styled } from 'styled-components'
import { Menu, MenuEl, WeblyIcon } from './Base'
import WeblyIconSrc from '../static/Webly_logo.png'

const Home = styled(MenuEl)``
const Projects = styled(MenuEl)``
const Contact = styled(MenuEl)``

const Line = styled.div`
  width: 100%;
  border: none;
  background: #d4d4d4;
  height: 1px;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Wrapper = styled.div`
  background: #efefef;
  position: sticky;
  top: 0px;
  z-index: 1;
`

interface HeaderProps {
  onHomeClick(): void
  onProjectsClick(): void
  onContactClick(): void
}

export function Header(props: HeaderProps) {
  return (
    <Wrapper>
      <Content>
        <WeblyIcon src={WeblyIconSrc} />
        <Menu>
          <Home onClick={props.onHomeClick}>home</Home>
          <Projects onClick={props.onProjectsClick}>projects</Projects>
          <Contact onClick={props.onContactClick}>contact</Contact>
        </Menu>
      </Content>
      <Line />
    </Wrapper>
  )
}
